<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="organizationTableColumns"
    :dataSource="organizationTableData"
    :loading="loadingOrganizations"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import moment from "moment";
import {useAbility} from "@casl/vue";

const organizationTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Status',
  dataIndex: 'active',
  key: 'active',
 },
 {
  title: 'Aangemaakt op',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const OrganizationListTable = defineComponent({
 name: 'OrganizationListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const router = useRouter();
  const {state, dispatch} = useStore();
  const {matched} = useRoute();
  const {path} = matched[1];
  const organizations = computed(() => state.organization.organizations);
  const loadingOrganizations = computed(() => state.organization.loadingOrganizations);
  const currentPage = computed(() => state.organization.currentPage);
  const count = computed(() => state.organization.count);
  const {can} = useAbility();
  const handleDelete = async (id) => {
   await dispatch('deleteOrganization', id);
   await dispatch('getOrganizations', {page: state.organization.current, pageSize: state.organization.pageSize});
  };
  const handleEdit = (id) => {
   router.push(`${path}/${id}`);
  };
  const organizationTableData = computed(() =>
   organizations.value.map((organization) => {
    const {id, name, email, active, createTimestamp, senderImageURL} = organization;

    return {
     key: id,
     id: id,
     name: (
      <div class="user-info">
       <figure>
        {senderImageURL && senderImageURL !== '' ? (
         <img style={{width: '40px'}} src={`${senderImageURL}`} alt=""/>
        ) : (
         <img style={{width: '40px'}} src={require('@/static/img/users/1.png')} alt=""/>
        )}
       </figure>
       <figcaption>
        <sdHeading className="user-name" as="h6">
         {name}
        </sdHeading>
        <span className="user-designation">{email}</span>
       </figcaption>
      </div>
     ),
     active: <span
      class={`status-text ${active ? 'active' : 'inactive'}`}>{active ? ('Actief') : ('Inactief')}</span>,
     createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
     action: (
      <div className="table-actions">
       {can('view', 'organization') ? (
        <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default"
                  shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       ) : ('')}
       {can('delete', 'organization') ? (
        <a-popconfirm
         title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
         ok-text="Ja"
         cancel-text="Nee"
         placement="leftTop"
         onConfirm={() => handleDelete(id)}
        >
         <sdButton className="btn-icon" type="default" to="#" shape="circle">
          <sdFeatherIcons type="trash-2" size="16"/>
         </sdButton>
        </a-popconfirm>
       ) : ('')}
      </div>
     ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled organisation', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageOrganization', event.current);
   await dispatch('getOrganizations', {page: event.current, pageSize: state.team.pageSize});
  }

  return {
   organizationTableColumns,
   organizationTableData,
   rowSelection,
   loadingOrganizations,
   currentPage,
   count,
   paginationChange
  };
 },
});

export default OrganizationListTable;
</script>

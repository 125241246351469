<template>
  <CardToolbox>
    <sdPageHeader :title="locale.page.organizationList.title">
      <template #subTitle>
        <span class="title-counter">{{ count }} organisatie{{ count > 0 && count < 2 ? '' : 's' }}</span>
        <AutoCompleteStyled style="width:100%" placeholder="Zoeken op naam...">
          <a-input type="input" v-model:value="search">
            <template #suffix>
              <sdFeatherIcons type="search"/>
            </template>
          </a-input>
        </AutoCompleteStyled>
      </template>
      <template #buttons>
        <OrganizationAdd v-if="$can('create', 'organization')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <OrganizationListTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import OrganizationListTable from './component/OrganizationTable';
import {computed, ref, defineComponent, onMounted, watch} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox, AutoCompleteStyled} from '../styled';
import OrganizationAdd from "./OrganizationAdd";
import locale from "@/static/locale/nl.json";

const OrganizationList = defineComponent({
  name: 'OrganizationList',
  components: {OrganizationAdd, Main, CardToolbox, OrganizationListTable, AutoCompleteStyled},
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.organization.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);
    const search = ref(null);
    let debounce = null;

    watch(search, (query) => {
      clearTimeout(debounce)
      debounce = setTimeout(async () => {
        const searchValues = {
          q: query,
          pageSize: state.organization.pageSize,
          page: 1,
        };
        await dispatch('setCurrentPageOrganization', 1);
        await dispatch('getOrganizations', searchValues);
      }, 600);
    });

    onMounted(() => {
      dispatch('getSenders');
      dispatch('getOrganizations', {page: state.organization.current, pageSize: state.organization.pageSize});
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
      locale,
      search,
    };
  },
});

export default OrganizationList;
</script>

<template>
  <sdDrawer
      ref="OrganizationAddRef"
      :form="form"
      title="Organisatie aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Opslaan"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateOrganization">
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="Naam" name="name">
              <a-input v-model:value="form.name" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-25">
            <a-form-item label="Afzender" name="senderID">
              <a-select v-model:value="form.senderID" size="large" class="sDash_fullwidth-select"
                        :allowClear="true" @change="handleChange('SENDER', $event)">
                <a-select-option name="senderID" v-for="(item,index) in senders"
                                 :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-25">
            <a-form-item label="Tweefactorauthenticatie" name="enforceTFA">
              <a-select v-model:value="form.enforceTFA" size="large" class="sDash_fullwidth-select"
                        :allowClear="true" @change="handleChange('TFA', $event)">
                <a-select-option name="enforceTFA" v-for="(item,index) in tfaOptions"
                                 :key="index" :value="item.value">
                  {{ item.key }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button class="btn-signin" type="primary" html-type="submit" :loading="loading"> Opslaan</a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {tfaOptions} from "../../utility/enums";

const UserAdd = defineComponent({
  name: 'OrganizationAdd',
  components: {
    FormValidationWrap,
  },
  data() {
    return {
      loading: false,
    }
  },
  setup() {
    const OrganizationAddRef = ref();
    const formRef = ref();
    const {state, dispatch} = useStore();
    const senders = computed(() => state.sender.senders);
    const form = reactive({
      name: null,
      senderID: null,
      enforceTFA: null,
      active: false,
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      enforceTFA: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      senderID: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const closeDrawer = () => {
      OrganizationAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getOrganizations')
    }
    const CreateOrganization = () => {
      dispatch('createOrganization', {value:form,close:closeDrawer});
    };
    const handleChange = (type, event) => {
      if (type === 'SENDER') {
        form.senderID = event;
        return;
      }
      if (type === 'TFA') {
        form.enforceTFA = event;
        return;
      }
    };
    return {
      form,
      rules,
      senders,
      CreateOrganization,
      handleChange,
      tfaOptions,
      OrganizationAddRef,
      formRef,
    };
  },
});

export default UserAdd;
</script>
